<template>
  <ui-component-modal
    :showModal="showModal"
    :modalTitle="'Edit tags'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <tag-input :tags="tags" @tagAdded="addTag" @tagDeleted="deleteTag" />
      </div>

      <div class="subtitle">
        {{ $t('Components.Reservation.ModalEditTags.Label_ChangeAllInSet') }}
      </div>
      <div class="tabs is-toggle is-small">
        <ul>
          <li :class="{ 'is-active': changeAllInSet === false }">
            <a @click="changeAllInSet = false">
              <span>{{
                $t('Components.Reservation.ModalEditTags.Label_No')
              }}</span>
            </a>
          </li>
          <li :class="{ 'is-active': changeAllInSet === true }">
            <a @click="changeAllInSet = true">
              <span>{{
                $t('Components.Reservation.ModalEditTags.Label_Yes')
              }}</span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

import TagInput from '@/components/Tags/TagInput'

export default {
  components: {
    'tag-input': TagInput,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      tags: [],
      mTags: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.tags =
      this.reservation.Tags.trim().length > 0
        ? this.reservation.Tags.trim().split(',')
        : []

    this.mTags = JSON.parse(JSON.stringify(this.tags))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addTag(tag) {
      this.mTags.push(tag)
    },

    deleteTag(tag) {
      let tagIndex = this.mTags.findIndex((t) => t === tag)
      if (tagIndex > -1) {
        this.mTags.splice(tagIndex, 1)
      }
    },

    saveReservation() {
      let self = this

      let reservation = JSON.parse(JSON.stringify(self.reservation))
      reservation.Tags = self.mTags.join(',')

      self.isSaving = true

      reservationProvider.methods
        .saveReservation(reservation, self.changeAllInSet)
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            // Set timer to self close the modal after 1.5 seconds
            setTimeout(() => {
              this.onClickCancel()
            }, 1500)

            // Send message to be shown as toast on page
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Reservation is saved!',
            })
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
          self.isSavingSuccess = true
        })
    },
  },
}
</script>
