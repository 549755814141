import axios from 'axios'
import store from '../store'

let getTagUsageToken = null

export default {
  methods: {
    cancelGetTagsRequest() {
      if (getTagUsageToken) {
        getTagUsageToken.cancel()
      }
    },

    cancelGetLocationTagUsageRequest() {
      if (getTagUsageToken) {
        getTagUsageToken.cancel()
      }
    },

    async getTags(searchTerm, page, itemsPerPage) {
      this.cancelGetTagsRequest()
      getTagUsageToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag`,
        {
          cancelToken: getTagUsageToken.token,

          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationTagUsage(locationId, month, year, nrOfTags, splitMonths) {
      this.cancelGetLocationTagUsageRequest()
      getTagUsageToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/usage/location/${locationId}`,
        {
          cancelToken: getTagUsageToken.token,

          params: {
            month: month,
            year: year,
            top: nrOfTags,
            splitMonths: splitMonths
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async extractKeywords(criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/extract`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getTagAutocomplete(input = "", nrOfItems = 10) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/autocomplete`,
        {
          params: {
            q: input,
            nrOfItems: nrOfItems
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getTagSuggestion(tag = "", nrOfItems = 10, channelId = 1) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/suggestion`,
        {
          params: {
            channelId: channelId,
            q: tag,
            nrOfItems: nrOfItems
          },
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getRelatedTags(tagId, searchTerm, top = 25, channelId = 1) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/tag/${tagId}/suggestions`,
        {
          params: {
            channelId: channelId,
            q: searchTerm,
            top: top
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getTagReach(tagId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/${tagId}/reach`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getTagUsagePerMonth(tagId, year, month, nrOfMonths, channelId = 1) {
      if (getTagUsageToken) {
        getTagUsageToken.cancel()
      }
      getTagUsageToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/${tagId}/usage`,
        {
          cancelToken: getTagUsageToken.token,
          params: {
            channelId: channelId,
            year: year,
            month: month,
            nrOfMonths: nrOfMonths
          },
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getTagRank(year, month = 0, top = 25, tagId = 0, channelId = 1) {
      if (getTagUsageToken) {
        getTagUsageToken.cancel()
      }
      getTagUsageToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tag/rank`,
        {
          cancelToken: getTagUsageToken.token,
          params: {
            channelId: channelId,
            year: year,
            month: month,
            top: top,
            tagId: tagId
          },
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
