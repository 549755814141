<template>
  <div class="">
    <transition-group
      name="fade"
      mode="out-in"
      tag="div"
      class="tagsarea textarea field is-grouped is-grouped-multiline"
      :class="{ 'is-borderless': inputBorderless }"
    >
      <div v-for="(tag, index) in mTags" :key="'t_' + index" class="control">
        <div class="tags has-addons">
          <span class="tag is-link">{{ tag }}</span>
          <a @click="removeTag(index)" class="tag is-delete"></a>
        </div>
      </div>
      <span key="tagInput" class="wrapper-input">
        <input
          ref="tagInput"
          type="text"
          v-model="tagInput"
          class="input input-tag"
          placeholder="Add tag"
          @keyup.enter="addTag"
        />
        <a
          @click="addTag"
          key="addTagButton"
          class="button is-small is-success"
          :class="{ 'is-success': tagInput, 'is-disabled': !tagInput }"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
        </a>
      </span>
    </transition-group>
  </div>
</template>

<script>
import tagProvider from '@/providers/tag'
export default {
  props: {
    inputBorderless: {
      type: Boolean,
      default: false
    },
    setFocusInput: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: function() {
        return []
      }
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLoadingTagsFromQuestion: false,
      tagInput: '',
      mTags: this.tags,
      tagInputPlaceHolder: 'Add tag',
      timer: null
    }
  },

  created() {},

  computed: {},

  methods: {
    /**
     * Add tag to tags array
     */
    addTag() {
      if (this.tagInput) {
        let tag = this.tagInput
        let arr = this.tagInput.split(/[.\*+-/_#]/)
        for (let i in arr) {
          if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
            this.mTags.push(arr[i].trim())
          }
        }
        arr = []

        this.tagInput = ''
        this.$refs.tagInput.focus()
        this.$emit('tagAdded', tag)
      }
    },

    /**
     * Remove tag from array
     */
    removeTag(index) {
      this.$emit('tagDeleted', this.tags[index])
      this.mTags.splice(index, 1)
    },

    /**
     * Check if tag already exists in array
     */
    tagAlreadyAdded(tag) {
      var index = this.mTags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';

.row-whyTags {
  display: flex;
  align-items: baseline;
  .message {
    margin-bottom: 0;
    padding: 2px 5px;
  }
}

.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}

.field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  background-color: $white;
  min-height: 80px;
  height: auto;

  &:last-child {
    margin-bottom: $gap;
  }

  .tags {
    .tag {
      font-size: $size-6;
    }
    a.is-delete {
      color: $text;
    }
  }

  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-tag {
      min-width: 140px;
      width: 140px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}
</style>
